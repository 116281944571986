<template>
   <v-main>
       <div class="navigation">
           <div class="justify-center navArea" >
               <ul >
                   <v-divider></v-divider>
                   <li class="justify-center">
                       <v-tab text to="/" @mouseenter='image=require(`../assets/Resized Pictures/fish1.webp`)'>Home</v-tab>
                   </li >
                   <v-divider></v-divider>
                   <li class="justify-center">
                       <v-tab text to="/portfolio" @mouseenter='image=require(`../assets/fish Website Pictures/jefferson-santos-9SoCnyQmkzI-unsplash.jpg`)'>Portfolio</v-tab>
                   </li>
                   <v-divider></v-divider>
                    <li class="justify-center">
                       <v-tab text to="/aboutus" @mouseenter='image=require(`../assets/fish Website Pictures/edho-pratama-yeB9jDmHm6M-unsplash.jpg`)'>About Us</v-tab>
                   </li>
                   <v-divider></v-divider>
                   <li class="justify-center">
                       <v-tab text to="/ourservices" @mouseenter='image=require(`../assets/fish Website Pictures/jefferson-santos-9SoCnyQmkzI-unsplash.jpg`)'>Our Services</v-tab>
                   </li>
                   <v-divider></v-divider>
                   <li class="justify-center">
                       <v-tab text to="/contact" @mouseenter='image=require(`../assets/fish Website Pictures/edho-pratama-yeB9jDmHm6M-unsplash.jpg`)'>Contact Us</v-tab>
                   </li>
                </ul>
           </div>
            <div class="imgArea" >
                    <v-img id="slider" :src='image' ></v-img>
                </div>
       </div>

   </v-main>
</template>
<script>
export default {
    name:'Navigation',
    
}
</script>
<style lang="css">
@import '../sass/nav.css'

</style>